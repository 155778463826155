<template>
	<section>
		<titulo-divisor titulo="Equipos vendidos">
			<div class="d-flex">
				<input-search 
				v-model="query" 
				mode="server"
				:auto-search="true"
				:search="search"
				placeholder="Buscar equipo"
				/>
				<filter-indicator 
				class="mx-2" 
				:count="filterCount" 
				@clearFilter="clearFilter"
				@openModalFilter="openModalFilterEtapas"
				/>
			</div>
		</titulo-divisor>
		<div class="equipos-container d-flex flex-wrap">
			<div 
			v-for="(item, index) in presenciaEquipos.data" 
			:key="index"
			@click="verEquipo(item)"
			>
				<equipo-card 	
				:image="item.imagen_producto"
				:name="item.nombre"
				:price="item.precio"
				:stages="item.etapas"
				:materials="item.materiales"
				:hours="item.horas_hombre"
				:saleDate="item.fecha_venta"
				:companyImage="item.foto_cliente"
				:companyName="item.cliente"
				/>
			</div>
		</div>
        <div class="d-middle-center mt-4">
            <Pagination :pagination="presenciaEquipos.pagination" layout="prev, pager, next, jumper" @paginate="listarPresenciaEquipos"/>
        </div>
		<modal-filter-etapas ref="modalFilterEtapas" @listarPresenciaEquipos="listarPresenciaEquipos"/>
	</section>
</template>

<script>
import EquipoCard from './components/equipoCard.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
	components: { 
		EquipoCard,
		modalFilterEtapas: () => import('./partials/modalFilterEtapas.vue')
	},
	data() {
		return {
			query: '',
		}
	},
	computed: {
		filterCount(){
			return this.filter.count()
		},
		...mapGetters({
            presenciaEquipos: 'ventas/presencia_equipos/presenciaEquipos',
			filter: 'ventas/presencia_equipos/filter'
        }),
	},
	async created(){
		await Promise.all(
			[
        		this.Action_get_equipos(),
        		this.Action_get_rangos(),
				this.Action_get_clientes()
			]
		)
        await this.clearFilter()
    },
	methods: {
		async listarPresenciaEquipos(page = 1){
            await this.Action_get_presencia_equipos({page, buscar:this.query})
        },
		...mapActions({
            Action_get_presencia_equipos: 'ventas/presencia_equipos/Action_get_presencia_equipos',
			Action_get_equipos: 'ventas/presencia_equipos/Action_get_equipos',
            Action_get_clientes: 'ventas/presencia_equipos/Action_get_clientes',
            Action_get_rangos: 'ventas/presencia_equipos/Action_get_rangos',
            Action_clean_filter: 'ventas/presencia_equipos/Action_clean_filter',
        }),
		verEquipo(item){
			this.$router.push({ name: 'ventas.presencia-equipos.detalle', params: { id: item.id, tabla:item.tabla} })
		},
		openModalFilterEtapas(){
			this.$refs.modalFilterEtapas.toggle()
		},
		clearFilter(){
			this.Action_clean_filter()
			this.listarPresenciaEquipos()
		},
		search(){
			this.listarPresenciaEquipos()
		}
	}
}
</script>

<style scoped lang="scss">
.equipos-container {
	gap: 60px
}
</style>