<template>
	<div class="equipo-card border br-5 d-flex flex-column cr-pointer">
		<div class="content">
			<p class="text-general obj-cover f-16 f-600 mb-2">{{ name }}</p>
			<div class="d-flex">
				<img class="br-5" :src="image | helper-storage" alt="">
				<div class="f-14 ml-4 d-flex flex-column" style="gap: 10px">
					<p>
						<span class="f-600">Precio: </span>
						<span> $ {{ formatoMoneda(price) }}</span>
					</p>
					<p>
						<span class="f-600">Etapas: </span>
						<span>{{ stages }}</span>
					</p>
					<p>
						<span class="f-600">Materiales: </span>
						<span>{{ materials }}</span>
					</p>
					<p>
						<span class="f-600">Horas hombre: </span>
						<span>{{ hours }}</span>
					</p>
					<p>
						<span class="f-600">Fecha de venta: </span>
						<span>{{ saleDate }}</span>
					</p>
				</div>
			</div>
		</div>
		<div class="footer border-top d-middle gap-2">
			<img class="rounded-circle obj-cover" :src="companyImage | helper-storage" :alt="name" >
			<p class="f-14">{{ companyName }}</p>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		name: {
			type: String,
			default: ''
		},
		image: {
			type: String
		},
		price: {
			type: String,
			default: 0
		},
		stages: {
			type: Number,
			default: 0
		},
		materials: {
			type: Number,
			default: 0
		},
		hours: {
			type: Number,
			default: 0
		},
		saleDate: {
			type: String,
			default: ''
		},
		companyImage: {
			type: String,
			default: ''
		},
		companyName: {
			type: String,
			default: '-'
		}
	}
}
</script>


<style scoped lang="scss">
.equipo-card{
	width: 450px;
	// height: 269px;
	.content {
		margin: 20px 24px 10px 24px;
		img {
			width: 151px;
			height: 151px;
		}
	}
	.footer{
		background-color: #F6F6F6;
		padding: 12px 28px;
		img {
			width: 35px;
			height: 35px;
		}
	}
}
img{
	pointer-events: none;
}
</style>